<template>
	<div id="apply-info" class="container">
		<div class="content">
			<div class="content_head">
				<div class="inner">
					<div class="breadcrumb">
						<ul>
							<li><a href="#" class="ico_home" title="Home"></a></li>
							<li>서비스 신청</li>
						</ul>
					</div>
					<h2 class="page_title">서비스 신청</h2>
				</div>
			</div>
			<div class="content_body">
				<div class="inner">
					<div class="apply_visual">
						<div class="text_wrap">
							<strong class="tit">잠들어있는 <b>데이터를 분석</b>하여<br> <b>인사이트</b>를 얻으세요.</strong>
							<p class="desc">궁금한 사항은 문의하기를 이용해 주세요</p>
						</div>
						<button @click="goPage" class="btn_apply">문의하기</button>
					</div>
					<!-- 페이지 단계 -->
					<nav class="page_step">
						<ul class="tab_list">
							<!-- 해당 페이지의 탭에 active 클래스 추가 -->
							<li class="tab_item active"><span class="num">1</span>기본 정보</li>
							<li class="tab_item"><span class="num">2</span>데이터 및 결제 정보</li>
							<li class="tab_item"><span class="num">3</span>입력 정보 확인</li>
						</ul>
					</nav>
					<!-- 기본정보 테이블 -->
					<div class="tbl_input">
						<table>
							<tr>
								<th>프로젝트명 <span class="require">*</span></th>
								<td>
									<div class="inp_box i1b1">
										<input id="project_name" type="text" class="inp"
											   v-model="serviceApplyVO.projectName"
											   placeholder="영문(소문자)만 입력 가능하며, 등록 후 수정할 수 없습니다.">
										<button v-if="!projectNameYn" type="button" class="btn"
												@click="checkProjectName">중복 확인
										</button>
										<button v-else type="button" class="btn" @click="reviseProjectName">수정하기
										</button>
									</div>
								</td>
							</tr>
							<tr>
								<th>회사명 <span class="require">*</span></th>
								<td>
									<div class="inp_box i1">
										<input type="text" class="inp" v-model="serviceApplyVO.companyName"
											   placeholder="회사명을 입력해 주세요.">
									</div>
								</td>
							</tr>
							<tr>
								<th>담당자명 <span class="require">*</span></th>
								<td>
									<div class="inp_box i1">
										<input type="text" class="inp" v-model="serviceApplyVO.managerName"
											   placeholder="담당자명을 입력해 주세요.">
									</div>
								</td>
							</tr>
							<tr>
								<th>수집 요청 채널 <span class="require">*</span></th>
								<td>
									<div class="inp_box">
										<select name="" id="" class="slt" v-model="serviceApplyVO.reqChannel">
											<option value="cafe">카페24</option>
											<option value="godo">고도몰/샵바이</option>
											<option value="shop">샵링커</option>
											<option value="ensol">에너지솔루션</option>
											<option value="anal">검색어분석</option>
											<option value="consul">컨설팅</option>
											<option value="etc">기타</option>
										</select>
									</div>
									<p class="tbl_info">
										해당사항이 없으시면 <b>기타</b>에 체크해주세요.
									</p>
								</td>
							</tr>
							<tr>
								<th>레포트 바탕색</th>
								<td>
									<div class="inp_box i1">
										<input type="text" class="inp" v-model="serviceApplyVO.backgroundColor"
											   placeholder="바탕색 정보를 입력해 주세요. (색상 코드, 색상명, 참고 URL 등)">
									</div>
								</td>
							</tr>
							<tr>
								<th>회사로고</th>
								<td>
									<div class="inp_box i1b2 file_box">
										<input type="text" class="inp file_name" disabled v-model="filename"
											   placeholder="파일은 최대 용량 10MB 이하로 등록해 주세요.">
										<input type="file" class="inp_file" @change="handleFileChange">
										<button type="button" class="btn btn_upload" @click="clickFileInput">파일 찾기
										</button>
										<button type="button" class="btn" @click="deleteFile">삭제</button>
									</div>
								</td>
							</tr>
						</table>
					</div>
					<div class="button_area">
						<button type="button" class="btn_cancle btn_black" @click="goBack">취소</button>
						<button type="submit" class="btn_submit btn_primary" @click="saveAndNextPage">다음</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import $ from "jquery";
import StringUtils from "../../commons/utils/string.utils";

export default {
	name: "applyInfo",

	data() {
		return {
			serviceApplyVO: new this.ServiceApplyVO(this.getSessionProc()),

			// 프로젝트명 중복확인 값
			projectNameYn: false,

			logoFile: {},
			filename: "",

			// 로고 변경 여부
			changeLogo: false
		}
	},
	mounted() {
		//console.log('++++++++++++FILE 확인', this.serviceApplyVO.fidx)
		this.setFileName();
	},
	methods: {
		goPage() {
			this.$router.push("/support/qna")
		},

		goBack() {
			history.back()
		},

		setFileName() {
			const self = this;

			if (this.serviceApplyVO.fidx != null && this.serviceApplyVO.fidx > 0) {
				//file 가져오기
				this.axiosCaller.post(this, this.APIs.BOARD_FILES + "/findFile", {fidx: this.serviceApplyVO.fidx}, (res) => {
					this.$eventBus.$emit("doneProgress")
					const result = res.data
					console.log(result)
					if (result.status === "ok") {
						if (result) {
							self.filename = result.fileVO.filename;
						}
					} else {
						self.swalUtils.gritter(result.msg, "", "error", 800)
					}
				});
			}
		},
		clickFileInput() {
			console.log('::::::::::::::clickFileInput')
			this.deleteFile();
			// $(".inp_file").click();
		},
		async goNextPage() {
			let sService = await this.getSessionProc();
			sService.serviceApplyVO = this.serviceApplyVO;
			await this.setSessionProc(sService)
			console.log(":::::::::sService.serviceApplyVO", sService.serviceApplyVO)

			this.$eventBus.$emit("doneProgress")
			// object
			location.href = '/service/data'
			// 이름을 가지는 라우트
			//router.push({ name: 'user', params: { userId: 123 }})
			// 쿼리와 함께 사용, 결과는 /register?plan=private
			//router.push({ path: 'register', query: { plan: 'private' }})
		},
		//파일 업로드
		uploadFile() {
			let self = this;
			let formData = new FormData;
			console.log('::::::this.logoFile', this.logoFile)
			formData.append('files', this.logoFile)
			formData.append('LOGIN_ID', this.getSessionProc().emailId)

			this.axiosCaller.postFormData(this, this.APIs.BOARD_FILES + "/upload", formData, (res) => {
				this.$eventBus.$emit("doneProgress")
				const result = res.data
				//console.log(result)
				console.log(':::::::::::postFormData')
				if (result.status === "ok") {
					self.serviceApplyVO.fidx = result.fileList[0].fidx;
					console.log(':::::::::::postFormData fidx', self.serviceApplyVO.fidx)
					self.goNextPage();
				} else {
					console.log('file upload 이슈')
				}
			})
		},
		// 프로젝트 명 중복확인
		checkProjectName() {
			const self = this;

			let projectNm = self.serviceApplyVO.projectName;

			// 영어 소문자가 아니거나 첫 글자가 숫자인 경우 리턴
			if (!StringUtils.isSmallLetterAndNumber(projectNm) || StringUtils.isFirstCharNum(projectNm)) {
				return self.swalUtils.gritter("영어 소문자만 입력해주세요.", "", "warning", 800)
			}

			this.axiosCaller.post(this, this.APIs.SERVICE_APPLY + "/checkProjectName", {projectName: this.serviceApplyVO.projectName}, (res) => {
				this.$eventBus.$emit("doneProgress")
				const result = res.data
				console.log(result)
				// 중복아님 (수정 불가능 하게 해야함.)
				if (result.status === "ok") {
					self.swalUtils.gritter("사용하실 수 있는 정보입니다", "", "success", 800)
					self.projectNameYn = true
					$('#project_name').attr('disabled', true)
				} 
				// 중복임
				if (result.status === "fail"){
					self.swalUtils.gritter("중복된 프로젝트명이 있습니다.\n다른 이름을 사용해 주세요.", "", "error", 1500)
				} 
			});
		},
		// 프로젝트명 수정하기
		reviseProjectName() {
			this.projectNameYn = false
			$('#project_name').attr('disabled', false)
		},
		// 저장후 다음 페이지로 이동
		async saveAndNextPage() {
			if (!this.isLogin()) {
				return self.swalUtils.gritter("로그인이 필요합니다", "", "error", 800)
			}

			if ($('#project_name').attr('disabled')!='disabled') {
				return self.swalUtils.gritter("프로젝트명 중복확인이 필요합니다", "", "error", 800)
			}

			if (this.serviceApplyVO.companyName=="" || this.serviceApplyVO.managerName=="") {
				return self.swalUtils.gritter("필수 입력란을 확인해주세요", "", "error", 800)
			}

			this.$eventBus.$emit("startProgress")
			//file upload
			if (this.changeLogo) {
				await this.uploadFile();
			} else {
				await this.goNextPage()
			}
		},
		//파일 교체
		handleFileChange(e) {
			console.log('::::::::::handleFileChange')
			this.logoFile = e.target.files[0];
			this.filename = e.target.files[0].name;
			this.changeLogo = true;
		},
		deleteFile() {
			let agent = navigator.userAgent.toLowerCase();
			if ((navigator.appName == 'Netscape' && navigator.userAgent.search('Trident') != -1) || (agent.indexOf("msie") != -1)) {
				// ie 일때 input[type=file] init.
				$(".inp_file").replaceWith($(".inp_file").clone(true));
			} else {
				//other browser 일때 input[type=file] init.
				$(".inp_file").val("");
			}
			this.fileName = "";
		},
	}
}
</script>

<style scoped>
input:disabled {
	background: #F0F0F0;
}
</style>