<template>
	<apply-info></apply-info>
</template>

<script>
import ApplyInfo from "@/components/apply/applyInfo";
export default {
	name: "apply_info",
	components: {ApplyInfo}
}
</script>

<style scoped>

</style>